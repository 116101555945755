import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [2,5];

export const dictionary = {
		"/(auth)": [6,[2]],
		"/(auth)/(app)/(require-strategy)/account": [7,[2,3,4]],
		"/account/(redirect-logged-in-users)/d4fd7858-f17e-4036-9471-d753b97e3d24": [17,[5]],
		"/account/(redirect-logged-in-users)/login": [18,[5]],
		"/account/reset-password": [19],
		"/account/reset-password/confirm": [20],
		"/account/verify-email": [21],
		"/(auth)/(app)/(require-strategy)/analyze": [8,[2,3,4]],
		"/(auth)/(app)/(require-strategy)/chat": [9,[2,3,4]],
		"/(auth)/(app)/(require-strategy)/home": [10,[2,3,4]],
		"/(auth)/onboarding": [~14,[2]],
		"/(auth)/onboarding/connect-socials": [15,[2]],
		"/(auth)/onboarding/intake-chat": [16,[2]],
		"/(auth)/(app)/(require-strategy)/post-creator": [11,[2,3,4]],
		"/(auth)/(app)/(require-strategy)/schedule": [12,[2,3,4]],
		"/(auth)/(app)/(require-strategy)/strategy": [13,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';